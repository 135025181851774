import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store";
import _ from "lodash";
import jQuery from "jquery";
import vuemoment from "vue-moment";
window.jQuery = jQuery;
window.$ = jQuery;

import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';

import "ionicons/dist/css/ionicons.min.css";
import "animate.css/animate.min.css";
import "bootstrap/dist/js/bootstrap";

import AllIosIcon from "vue-ionicons/dist/ionicons-ios.js";
import VTooltip from "v-tooltip";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import i18n from './lang/i18n'

import SocialSharing from "vue-social-sharing";
Vue.use(SocialSharing);
Vue.use(VTooltip);
Vue.use(vuemoment);
Vue.use(AllIosIcon);
Vue.use(VueSweetalert2);
Vue.component(VueQrcode.name, VueQrcode);

VTooltip.options.defaultTemplate = '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>';
VTooltip.options.defaultArrowSelector = '.tooltip-vue-arrow, .tooltip-vue__arrow';
VTooltip.options.defaultInnerSelector = '.tooltip-vue-inner, .tooltip-vue__inner';
VTooltip.options.defaultDelay = {show: 400, hide: 0};
VTooltip.options.defaultTrigger = "hover";

Vue.config.productionTip = false;

const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
export default app;
