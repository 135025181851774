let setStyle = require("@/libs-core/set-style-block.min.js");

export default {
    changeDevice(context, val) {
        var api = context.state.api;
        let listPage = context.state.listPage;
        let pageSelect = context.state.pageSelect;
        if (api && api.length > 0 && val != "mobile") {
            api.find(function(x) {
                if (!x[val] || !x[val].top) {
                    x[val] = $.extend(true, {}, x.mobile);
                    if (
                        val == "desktop" &&
                        x.componentName != "blockColumnOnly" &&
                        x.componentName != "blockList" &&
                        x.componentName != "blockLightBox"
                    ) {
                        x[val].left = 390 + parseFloat(x["mobile"].left) + "px";
                    }
                    if (
                        val == "tablet" &&
                        x.componentName != "blockColumnOnly" &&
                        x.componentName != "blockList" &&
                        x.componentName != "blockLightBox"
                    ) {
                        x[val].left = 224 + parseFloat(x["mobile"].left) + "px";
                    }
                }
            });
            listPage.find(function(x) {
                if (x.name === pageSelect) {
                    x.api = api;
                }
            });
            context.commit("setApi", api);
            //context.commit("setListPage", listPage);
        }
        setStyle.init(api, val);
    },
    updateApi(context, val) {
        context.commit("setApi", val);
    },
    updateBlock(context, value) {
        if (!value) return;
        let val = value.val;
        let type = value.type;
        if (!val) {
            val = value;
        }
        let api = context.state.api;
        let device = context.state.device;
        let listPage = context.state.listPage;
        let pageSelect = context.state.pageSelect;
        let index = api.findIndex((k) => k.id === val.id);

        let block = api[index];
        if (!api[index]) return;

        //UPDATE DATA BLOCK
        switch (type) {
            case "backgroundSt":
                block[device].background = val[device].background;
                break;
            case "bgInsideSt":
                block[device].backgroundInside = val[device].backgroundInside;
                break;
            case "listSt":
                block.listItem = val.listItem;
                block.settingList = val.settingList;
                block.dataColect = val.dataColect;
                block.keyDataPar = val.keyDataPar;
                break;
            case "rangeSt":
                block.data = val.data;
                break;
            case "svgSt":
                block.stroke = val.stroke;
                block.fill = val.fill;
                block.strokeWidth = val.strokeWidth;
                break;
            case "htmlSvgSt":
                block.html = val.html;
                break;
            case "textSt":
                block.color = val.color;
                block.isGraPicker = val.isGraPicker;
                block.colorText = val.colorText;
                block.colorGraText = val.colorGraText;
                block[device].text = val[device].text;
                break;
            case "valueTextSt":
                block.html = val.html;
                block.dataInput = val.dataInput;
            case "videoSt":
                block.video = val.video;
                block.dataInput = val.dataInput;
                break;
            case "borderSt":
                block.border = val.border;
                block[device].border = val.border;
                break;
            case "spacing":
                block.spacing = val.spacing;
                break;
            case "padding":
                block.padding = val.padding;
                break;
            case "borderInsideSt":
                block.borderInside = val.borderInside;
                block[device].borderInside = val[device].borderInside;
                break;
            case "radiusSt":
                if (!block.border) {
                    block.border = {};
                }
                if (!block[device].border) {
                    block[device].border = {};
                }
                block.border.borderRadius = val.border.borderRadius;
                block[device].border.borderRadius = val[device].border.borderRadius;
                break;
            case "shadowSt":
                block.shadow = val.shadow;
                block.filter = val.filter;
                break;
            case "inputSt":
                block.input = val.input;
                block.dataInput = val.dataInput;
                block.dataOutput = val.dataOutput;
                break;
            case "itemListSt":
                block.listItem = val.listItem;
                break;
            case "selectSt":
                block.selectOption = val.selectOption;
                block.dataInput = val.dataInput;
                break;
            case "stickySt":
                block.sticky = val.sticky;
                break;
            case "conditionSt":
                block.condition = val.condition;
                break;
            case "quickFuncSt":
                block[device].top = val[device].top;
                block[device].left = val[device].left;
                block[device].width = val[device].width;
                block[device].height = val[device].height;
                break;
            case "sizeBlockSt":
                block[device].width = val[device].width;
                block[device].height = val[device].height;
                break;
            case "posBlockSt":
                block[device].top = val[device].top;
                block[device].left = val[device].left;
                block[device].width = val[device].width;
                block[device].height = val[device].height;
                block.transform = val.transform;
                block.idPar = val.idPar;
                break;
            case "cssAdvanceSt":
                block.cssAdvance = val.cssAdvance;
                if (val[device] && val[device].cssAdvance) {
                    block[device].cssAdvance = val[device].cssAdvance;
                }
                break;
            case "actionSt":
                block.dataAction = val.dataAction;
                break;
            case "animationSt":
                block.animated = val.animated;
                break;
            case "imageSt":
                block.src = val.src;
                block.dataInput = val.dataInput;
                block.objectfit = val.objectfit
                break;
            case "effectSt":
                block[device].effect = val[device].effect;
                break;
            case "effectTextSt":
                if (val[device].text) {
                    block[device].text.effect = val[device].text.effect;
                }
                break;
            case "themeSt":
                block.theme = val.theme;
                break;
            case "sliderst":
                block.listItemSlider = val.listItemSlider;
                block.dataCol = val.dataCol;
                block.typeSlide = val.typeSlide;
                block.tyleSlide = val.tyleSlide;
                break;
            default:
                block = $.extend(true, block, val);
        }
        //UPDATE DATA BLOCK
        api[index] = block;
        let time_update = new Date().getTime();
        api[index].time_update = time_update;

        listPage.find(function(x) {
            if (x.name == pageSelect) {
                x.api = $.extend(true, [], api);
            }
        });

        context.commit("setApi", api);
        context.commit("setTimeUpdate", time_update);
        //context.commit("setListPage", listPage);
        setStyle.setStypeItem(block, context.state.device);
    },
    updateBackground: function(context, val) {
        if (val && val.id && val.mobile && val.mobile.background) {
            let api = context.state.api;
            let index = api.findIndex((k) => k.id === val.id);
            if (!api[index].mobile) {
                api[index].mobile = {};
            }
            api[index].mobile.background = $.extend(true, {}, val.mobile.background);
            this.updateBlock(api[index]);
        }
    },
    updateBorder: function(context, val) {
        if (val && val.id && val.mobile && val.border) {
            let api = context.state.api;
            let index = api.findIndex((k) => k.id === val.id);
            if (!api[index].mobile) {
                api[index].mobile = {};
            }
            api[index].border = $.extend(true, {}, val.border);
            this.updateBlock(api[index]);
        }
    },
    removePage(context, val) {
        context.state.listPage = [];
        context.state.listPage.length = 0;
        context.commit("setListPage", context.state.listPage);
    }
};
