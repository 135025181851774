export default {
  /////////////
  // CORE STATE
  /////////////
  setApi(state, api) {
    state.api = $.extend(true, [], api);
    state.listPage.find(function (x) {
      if (x.name == state.pageSelect) {
        console.log("pageSelect::: ", state.pageSelect)
        x.api = null;
        x.api = $.extend(true, [], state.api);
      }
    })
  },

  setBlockSelected(state, block) { state.blockSelected = block; },
  setPageSelect(state, pageSelect) { state.pageSelect = pageSelect },

  setListPage(state, listPage) {
    state.listPage = null; state.listPage = $.extend(true, [], listPage);
    console.log("state.listPage:: ", state.listPage);
  },
  setListModel(state, listModel) { state.listModel = listModel },

  setPageAction(state, pageAction) { state.pageAction = pageAction },

  setBackgroundPage(state, backgroundPage) { state.backgroundPage = backgroundPage },
  setSettingPage(state, settingPage) { state.settingPage = settingPage },
  setInfoApp(state, infoApp) { state.infoApp = infoApp },
  setDomain(state, domain) { state.domain = domain },

  setDataApp(state, dataApp) { state.dataApp = dataApp },
  setListFunction(state, listFunction) { state.listFunction = listFunction },
  setListTracking(state, listTracking) { state.listTracking = listTracking },
  setListApiTemplate(state, listApiTemplate) { state.listApiTemplate = listApiTemplate },

  setTypeManagerImage(state, typeManagerImage) { state.typeManagerImage = typeManagerImage },
  setDataChangeImage(state, dataChangeImage) { state.dataChangeImage = dataChangeImage },
  setTimeUpdate(state, timeUpdate) { state.timeUpdate = timeUpdate },
  setUpdateBlock(state, updateBlock) { state.updateBlock = updateBlock },
  // END CORE STATE
  /////////////////


  mutaListImage(state, data) { state.list_image = data; },
  mutaCateAndImage(state, data) { state.cate_and_image = data; },
  mutaBeforePopup(state, data) { state.before_popup = data; },
  mutaTimeShowDataApp(state, data) { state.timeShowDataApp = data; },
  mutaDataAppSelect(state, data) { state.dataAppSelect = data },
  setDevice(state, device) { state.device = device; },
  mutaSaveTempStatus(state, status) { state.saveTempStatus = status; },
  mutaDataActionSelect(state, data) { state.dataActionSelect = data; },
  setSourceTemplate(state, data) { state.sourceTemplate = data; },
  setActionEditor(state, data) { state.actionEditor = data; },
  setSelectBlock(state, data) { state.selectBlock = data; },
  setDataAddOn(state, data) { state.dataAddOn = data; },
  setDbClickBlock(state, data) { state.dbclickBlock = data; }
};
