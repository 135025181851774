import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import constant from "@/constants/index.js";

export default {
  namespaced: true,
  state: {
    /////////////
    // CORE STATE
    /////////////
    api: [],

    blockSelected: {},
    pageSelect: 'home',

    listPage: [{ name: "home", api: [] }],
    listModel: constant._model.variable_default,

    page_action: [],

    backgroundPage: {},
    settingPage: {},
    infoApp: {
      name: '',
      type: '',
      category: ''
    },
    domain: '',

    dataApp: {},
    list_function: [],
    list_tracking: [],
    listApiTemplate: [],

    typeManagerImage: '',
    dataChangeImage: '',
    timeUpdate: new Date().getTime(),
    updateBlock: {},
    // END CORE STATE
    /////////////////


    list_image: [],
    cate_and_image: [],
    before_popup: {},
    timeShowDataApp: 0,
    dataAppSelect: {},
    saveTempStatus: '',
    dataActionSelect: {},
    dataAddOn: {},
    sourceTemplate : {},
    actionEditor: {},
    selectBlock : {},
    dbclickBlock: false,
    // DEVICE STATE
    device: "mobile" // mobile, tablet, desktop
  },
  getters,
  mutations,
  actions
};
