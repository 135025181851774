export default {
  setEmail(state, email) {
    state.email = email;
  },
  setPassword(state, password) {
    state.password = password;
  },
  setFirst_name(state, first_name) {
    state.first_name = first_name;
  },
  setLast_name(state, last_name) {
    state.last_name = last_name;
  },
  setGender(state, gender) {
    state.gender = gender;
  }
};
