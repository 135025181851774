import { action } from "./action";
import { api } from "./api";
import { block } from "./block";
import { chatbot } from "./chatbot";
import { COMMON } from "./common";
import { condition } from "./condition";
import { env } from "./env";
import { helper } from "./helper";
import { i18n } from "./i18n";
import { lightbox } from "./lightbox";
import { model } from "./model.js";
import { publish } from "./publish";
import { setting_block } from "./setting_block";
import { template } from "./template";
import { tracking } from "./tracking";
import { variable_global } from "./variable_global";
import tribute from "./tribute";

export default {
  _env: env,
  _common: COMMON,
  _api: api,
  _model: model,
  _block: block,
  _action: action,
  _publish: publish,
  _setting_block: setting_block,
  _variable_global: variable_global,
  _condition: condition,
  _template: template,
  _tracking: tracking,
  _chatbot: chatbot,
  _helper: helper,
  _lightbox: lightbox,
  _i18n: i18n,
  _tribute: tribute
};
