/* eslint-disable no-undef */
export default {
  options: function options($listModel) {
    return {
      trigger: "{",
      selectClass: "menu-highlighted",
      // noMatchTemplate: function() {
      //   return "<p class='no-match'>Not found variable</p>";
      // },
      values: $listModel,
      selectTemplate: function(item) {
        return "{{" + item.original.key + "}}";
      },
      menuItemTemplate: function(item) {
        return item.original.key + " (" + item.original.type + ")";
      },
      requireLeadingSpace: false,
      spaceSelectsMatch: true
    };
  }
};
