import Vue from "vue";
import Router from "vue-router";
const Editor3 = () => import(/* webpackChunkName: "Editor" */ "@/views/editor-v3/editor-v3.vue");
const Editor4 = () => import(/* webpackChunkName: "Editor" */ "@/views/editor-v4/editor-v4.vue");
const Dashboard = () =>
  import(/* webpackChunkName: "Dashboard" */ "@/views/dashboard/dashboard.vue");
const Login = () =>
  import(/* webpackChunkName: "Login" */ "@/views/login/login.vue");
const Register = () =>
  import(/* webpackChunkName: "register" */ "@/views/register/register.vue");
const OauthGoogleSheet = () =>
  import(/* webpackChunkName: "OauthGoogleSheet" */ "@/views/oauth-google/oauth-google.vue");
const Category = () => import(/* webpackChunkName: "Category" */ "@/views/category/category.vue");
const ForgotPassword = () => import(/* webpackChunkName: "ForgotPassword" */ "@/views/forgot-password/forgot-password.vue");
const UpdatePassword = () => import(/* webpackChunkName: "UpdatePassword" */ "@/views/update-password/update-password.vue");
const ThemesBotup = () => import(/* webpackChunkName: "ThemesBotup" */ "@/views/themes-botup/themes-botup.vue");
const ThankyouPayment = () => import(/* webpackChunkName: "ThankyouPayment" */ "@/views/thankyou-payment/thankyou-payment.vue");

const Profile = () => import("@/views/profile/profile.vue");
const ReviewTemplate = () => import("@/views/review-template/review-template.vue");
const Test = () => import(/* webpackChunkName: "Test" */"@/views/test/test.vue");
Vue.use(Router);
const router = new Router({
  mode: "history",
  base: "/studio",
  root: "/",
  routes: [
    {
      path: "/",
      component: Dashboard
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
    },
    {
      path: "/dashboard/:id",
      name: "DashboardId",
      component: Dashboard,
    },
    {
      path: "/themes",
      name: "ThemesBotup",
      component: ThemesBotup
    },
    {
      path: "/editor-v3/:id",
      name: "Editor3",
      component: Editor3
    },
    {
      path: "/editor-v4/:id",
      name: "Editor4",
      component: Editor4
    },
    {
      path: "/oauth/oauthgooglesheet",
      name: "OauthGoogleSheet",
      component: OauthGoogleSheet
    },
    {
      path: "/category",
      name: "Category",
      component: Category
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/logout",
      name: "Logout",
      component: Login
    },
    {
      path: "/register",
      name: "Register",
      component: Register
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: ForgotPassword
    },
    {
      path: "/update-password",
      name: "UpdatePassword",
      component: UpdatePassword
    },
    {
      path: "/thankyou-payment",
      name: "Thankyou Payment",
      component: ThankyouPayment
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile
    },
    {
      path: "/review-template",
      name: "ReviewTemplate",
      component: ReviewTemplate
    },
    {
      path: "/test-test",
      name: "Test",
      component: Test
    }
  ]
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (to.name != "Login" && to.name != "Register" && to.name != "ThemesBotup") {
    if (
      !localStorage.getItem("Authorization") ||
      localStorage.getItem("Authorization") === "undefined"
    ) {
      next({ name: "Login" });
    }
  }
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }
});

export default router;
