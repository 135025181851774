export const api = {
  manage_api_template: {
    api_data: {
      name: "",
      description: "",
      type: "api",
      uri: "",
      keys: [],
      keys_tags: [],
      method: "GET"
    }
  }
};
