import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state: {
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    gender: ""
  },
  getters,
  mutations,
  actions
};
