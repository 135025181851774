import Vue from "vue";
import Vuex from "vuex";

// import module
import login from "./modules/login/store";
import editor from "./modules/editor/store";
import lang from "./modules/lang/store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    editor,
    lang
  }
});
