/* eslint-disable no-useless-escape */
export const model = {
  variable_default: [
    {
      key: "mid",
      description: "ID Messenger of user.",
      type: "string",
      value: "",
      isRemove: false
    },
    {
      key: "timestamp",
      description: "Timestamp on device of user.",
      value: new Date().getTime(),
      type: "number",
      isRemove: false
    }
  ]
};
