import login from "@/services/login/login.js";
import register from "@/services/login/register.js";

export default {
  async login({ state }, next) {
    const res = await login.login({
      email: state.email,
      password: state.password
    });
    next(res);
  },
  async register({ state }) {
    const res = await register.register({
      email: state.email,
      password: state.password,
      first_name: state.first_name,
      last_name: state.last_name
    });

    console.log(res);
  }
  // async fbLogin({ commit, state }) {
  //   const res = await login.fbLogin({
  //     // ?
  //   })

  //   console.log(res)
  // },
};
